import { storableError } from '../../util/errors';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import {
  parseDateFromISO8601,
  getExclusiveEndDate,
  addTime,
  subtractTime,
  daysBetween,
  getStartOf,
} from '../../util/dates';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { isOriginInUse, isStockInUse } from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 24;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchListings = (searchParams, config, bounds) => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));
  // SearchPage can enforce listing query to only those listings with valid listingType
  // NOTE: this only works if you have set 'enum' type search schema to listing's public data fields
  //       - listingType
  //       Same setup could be expanded to 2 other extended data fields:
  //       - transactionProcessAlias
  //       - unitType
  //       ...and then turned enforceValidListingType config to true in configListing.js
  // Read More:
  // https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
  const searchValidListingTypes = listingTypes => {
    return config.listing.enforceValidListingType
      ? {
        pub_listingType: listingTypes.map(l => l.listingType),
        // pub_transactionProcessAlias: listingTypes.map(l => l.transactionType.alias),
        // pub_unitType: listingTypes.map(l => l.transactionType.unitType),
      }
      : {};
  };

  const priceSearchParams = priceParam => {
    const inSubunits = value => convertUnitToSubUnit(value, unitDivisor(config.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
        price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
      }
      : {};
  };

  // const distanceSearchParms = distanceParam => {
  //   const inSubunits = value => convertUnitToSubUnit(value, unitDivisor(config.currency));
  //   const values = priceParam ? priceParam.split(',') : [];
  //   return priceParam && values.length === 2
  //     ? {
  //         price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
  //       }
  //     : {};
  // };

  const datesSearchParams = datesParam => {
    const searchTZ = 'Etc/UTC';
    const datesFilter = config.search.defaultFilters.find(f => f.key === 'dates');
    const values = datesParam ? datesParam.split(',') : [];
    const hasValues = datesFilter && datesParam && values.length === 2;
    const { dateRangeMode, availability } = datesFilter || {};
    const isNightlyMode = dateRangeMode === 'night';
    const isEntireRangeAvailable = availability === 'time-full';

    // SearchPage need to use a single time zone but listings can have different time zones
    // We need to expand/prolong the time window (start & end) to cover other time zones too.
    //
    // NOTE: you might want to consider changing UI so that
    //   1) location is always asked first before date range
    //   2) use some 3rd party service to convert location to time zone (IANA tz name)
    //   3) Make exact dates filtering against that specific time zone
    //   This setup would be better for dates filter,
    //   but it enforces a UX where location is always asked first and therefore configurability
    const getProlongedStart = date => subtractTime(date, 14, 'hours', searchTZ);
    const getProlongedEnd = date => addTime(date, 12, 'hours', searchTZ);

    const startDate = hasValues ? parseDateFromISO8601(values[0], searchTZ) : null;
    const endRaw = hasValues ? parseDateFromISO8601(values[1], searchTZ) : null;
    const endDate =
      hasValues && isNightlyMode
        ? endRaw
        : hasValues
          ? getExclusiveEndDate(endRaw, searchTZ)
          : null;

    const today = getStartOf(new Date(), 'day', searchTZ);
    const possibleStartDate = subtractTime(today, 14, 'hours', searchTZ);
    const hasValidDates =
      hasValues &&
      startDate.getTime() >= possibleStartDate.getTime() &&
      startDate.getTime() <= endDate.getTime();

    const dayCount = isEntireRangeAvailable ? daysBetween(startDate, endDate) : 1;
    const day = 1440;
    const hour = 60;
    // When entire range is required to be available, we count minutes of included date range,
    // but there's a need to subtract one hour due to possibility of daylight saving time.
    // If partial range is needed, then we just make sure that the shortest time unit supported
    // is available within the range.
    // You might want to customize this to match with your time units (e.g. day: 1440 - 60)
    const minDuration = isEntireRangeAvailable ? dayCount * day - hour : hour;
    return hasValidDates
      ? {
        start: getProlongedStart(startDate),
        end: getProlongedEnd(endDate),
        // Availability can be time-full or time-partial.
        // However, due to prolonged time window, we need to use time-partial.
        availability: 'time-partial',
        // minDuration uses minutes
        minDuration,
      }
      : {};
  };

  const stockFilters = datesMaybe => {
    const hasDatesFilterInUse = Object.keys(datesMaybe).length > 0;

    // If dates filter is not in use,
    //   1) Add minStock filter with default value (1)
    //   2) Add relaxed stockMode: "match-undefined"
    // The latter is used to filter out all the listings that explicitly are out of stock,
    // but keeps bookable and inquiry listings.
    return hasDatesFilterInUse ? {} : { minStock: 1, stockMode: 'match-undefined' };
  };

  // const distanceSearch =

  const { perPage, price, dates, sort, ...rest } = searchParams;
  // const distanceSearchMayBe = distanceSearchParms(distance);
  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates);
  const stockMaybe = stockFilters(datesMaybe);
  const sortMaybe = sort === config.search.sortConfig.relevanceKey ? {} : { sort };

  const params = {
    ...rest,
    // ...distanceSearchMayBe,
    ...priceMaybe,
    ...datesMaybe,
    ...stockMaybe,
    ...sortMaybe,
    ...searchValidListingTypes(config.listing.listingTypes),
    pub_listingType: "employee",
    pub_employeeHire: false,
    perPage,
  };
  if (bounds) {
    params.bounds = bounds;
  }
  return sdk.listings
    .query(params)
    .then(response => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };

      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

function extractLatLong(queryString) {
  // Use URLSearchParams to parse the query string
  const params = new URLSearchParams(queryString);

  // Get the bounds parameter
  const bounds = params.get('bounds');

  if (!bounds) {
    return null;
  }

  // Split the bounds string into its components
  const [lat1, long1, lat2, long2] = bounds.split(',').map(Number) || false;

  return {
    latitude: [lat1, lat2],
    longitude: [long1, long2],
  };
}

function toRadians(degrees) {
  return (degrees * Math.PI) / 180;
}
function toDegrees(radians) {
  return (radians * 180) / Math.PI;
}
function getBoundingBox(lat, lng, distance) {
  const earthRadius = 6371; // Radius of the Earth in kilometers
  // Convert distance to radians
  const radDist = distance / earthRadius;
  // Convert latitude and longitude from degrees to radians
  const radLat = toRadians(lat);
  const radLng = toRadians(lng);
  // Calculate the bounding box coordinates
  const minLat = radLat - radDist;
  const maxLat = radLat + radDist;
  const minLng = radLng - radDist / Math.cos(radLat);
  const maxLng = radLng + radDist / Math.cos(radLat);
  // Convert back to degrees
  // NE lat, NE, lng, SW lat and SW lng.
  return `${toDegrees(maxLat)},${toDegrees(maxLng)},${toDegrees(minLat)},${toDegrees(minLng)}`;
  return {
    NE: {
      lat: toDegrees(maxLat),
      lng: toDegrees(maxLng),
    },
    SW: {
      lat: toDegrees(minLat),
      lng: toDegrees(minLng),
    },
  };
}

export const loadData = (params, search, config) => {
  const temp = extractLatLong(search);
  // const distance = 200;
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { page = 1, address, origin, ...rest } = queryParams;
  const { distance = 200 } = rest;
  const originMaybe = isOriginInUse(config) && origin ? { origin } : {};
  let bounds = false;
  if (temp) {
    bounds = getBoundingBox(temp?.latitude[0], temp?.longitude[0], distance)
      ? getBoundingBox(temp?.latitude[0], temp?.longitude[0], distance)
      : false;
  }

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return searchListings(
    {
      ...rest,
      ...originMaybe,
      page,
      perPage: RESULT_PAGE_SIZE,
      include: ['author', 'author.profileImage', 'images'],
      'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'author.profileImage'],
      'fields.image': [
        'variants.scaled-small',
        'variants.scaled-medium',
        `variants.${variantPrefix}`,
        `variants.${variantPrefix}-2x`,
      ],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      'limit.images': 1,
    },
    config,
    bounds
  );
};
